import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import PostList from './post-list-tag'



const WorksList = () => {
  const data = useStaticQuery(graphql`
    query {
      postWorks: allMdx(
        limit: 3,
        sort: {fields: frontmatter___datePublished, order: DESC},
        filter: {
          frontmatter: {draft: {eq: false}},
          slug: {regex: "/^(works/.*|personal-works/.*)/"}
        }
      ) {
        edges {
          node {
            slug
            frontmatter {
              tags
              draft
              eyecatch {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    aspectRatio: 2
                    width: 312
                  )
                }
              }
              dateModified(formatString: "MMMM Do, YYYY")
              title
            }
          }
        }
      }
      site {
        siteMetadata {
          categories {
            label
            slug
          }
        }
      }
    }
  `)
  const postWorksList = data.postWorks.edges
  const catsMeta = data.site.siteMetadata.categories
  return (
    <PostList
      className="pt-48"
      header="New Works"
      postList={postWorksList}
      catsMeta={catsMeta}
    />
  );
}



export default WorksList
