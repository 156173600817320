import React from "react"
import { Link } from "gatsby"

const PostListNav = ({className}) => (
  <nav className={`${className} w-11/12 md:w-2/3 lg:w-1/2 mx-auto border-t border-gray-300`}>
    <Link className="block w-40 pt-6 mx-auto text-sm text-center hover:underline" to="/works/">All Works</Link>
  </nav>
)

export default PostListNav
