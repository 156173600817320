import React from "react"
import { Link } from "gatsby"



const ListItemTag = ({name, count, linkTo, active, makeLink}) => {
  const countTag = count
    ? (<span className="ml-2 text-xs text-gray-400">{count}</span>)
    : null
  return (
    <li className={`inline-block mr-2 mb-1 px-3 text-gray-600 rounded-md border border-gray-300 text-xs leading-loose ${active
      ? "bg-gray-500 bg-opacity-25"
      : makeLink
        ? "hover:bg-gray-500 hover:bg-opacity-25"
        : "border-dashed"
    }`}>
      {makeLink
        ? <Link to={linkTo}>#{name}{countTag}</Link>
        : <span>#{name}{countTag}</span>
      }
    </li>
  )
}

export default ListItemTag
