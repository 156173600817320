import React from "react"
import Article from './list-card-article'
import Tag from './list-item-tag'

import { random } from '../util/random'



const PostListTag = ({className, tag, header, postList, tags, tagsMeta, catsMeta, isH1}) => {
  let scrollTarget = ""
  if ( tag && tags && tagsMeta ) {
    tags.group.sort(function(a,b){
      if(a.fieldValue < b.fieldValue) return 1;
      if(a.fieldValue > b.fieldValue) return -1;
      return 0;
    })
    scrollTarget = "post-list"
  }
  return (
    <div className={className}>
      { isH1
        ? <h1 className="px-4 text-3xl md:text-4xl text-center font-bold leading-snug" id={scrollTarget}>{header}</h1>
        : <h2 className="px-4 text-3xl md:text-4xl text-center font-bold leading-snug" id={scrollTarget}>{header}</h2>
      }
      { tag && tags && tagsMeta &&
        <ul className="pt-12 px-6 text-center w-full max-w-4xl mx-auto">
          <Tag
            key={random()}
            name="ALL"
            linkTo="/#post-list"
            active={tag.toLowerCase()==='all' ? true : false}
            makeLink={tag.toLowerCase()==='all' ? false : true}
          />
          {tags.group.map(_Tag => {
            const _TagMeta = tagsMeta.find(_TagMeta => {
              return (_TagMeta.label === _Tag.fieldValue)
            })
            return (_TagMeta
              ? <Tag
                key={random()}
                name={_TagMeta.label}
                count={_Tag.totalCount}
                linkTo={`/tag/${_TagMeta.slug}/#post-list`}
                active={tag.toLowerCase()===_TagMeta.label.toLowerCase() ? true : false}
                makeLink={tag.toLowerCase()===_TagMeta.label.toLowerCase() ? false : true}
              />
              : <Tag
                key={random()}
                name={_Tag.fieldValue}
                count={_Tag.totalCount}
                makeLink={false}
              />
            )
          })}
        </ul>
      }
      <div className="pt-6 px-6 w-full max-w-1800 mx-auto flex flex-row flex-wrap justify-center">
        { postList.map( ( { node } ) => {
          let catSlug
          catsMeta.forEach( catMeta => {
            if ( ~node.slug.indexOf( 'post/' ) ) catSlug = 'post'
            else if ( ~node.slug.indexOf( `${catMeta.slug }/` ) ) catSlug = catMeta.slug
          })
          return (
            catSlug &&
            node.frontmatter.eyecatch &&
            <Article
              title={node.frontmatter.title}
              imageSharp={node.frontmatter.eyecatch}
              imageAlt={node.frontmatter.title}
              linkTo={`/${node.slug}/`}
              date={node.frontmatter.dateModified}
              key={random()}
            />
          )
        } ) }
      </div>
    </div>
  )
}

export default PostListTag
