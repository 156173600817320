import React from "react"
import { graphql } from 'gatsby'

import Layout from '../components/ui/layout'
import postListLdjson from '../components/ui/post-list-ldjson'
import Seo from '../components/ui/seo'
import Recommend from '../components/ui/recommend'
import NewWorks from '../components/ui/new-works'
import NewWorksNav from '../components/ui/new-works-nav'
import PostList from '../components/ui/post-list-tag'
import Nav from '../components/ui/post-list-nav'

const IndexTemplate = ({ data }) => {
  const postList = data.posts.edges
  const tags = data.tags
  const tagsMeta = data.site.siteMetadata.tags
  const catsMeta = data.site.siteMetadata.categories
  return (
    <Layout isH1={true}>
      <Seo
        isMainLdJSON={true}
        postList={postListLdjson({
          postList: postList,
          catsMeta: catsMeta,
        })}
      />
      <main>
        <Recommend className="pt-48" />
        <NewWorks/>
        <NewWorksNav className="mt-12" />
        <PostList
          className="pt-48"
          tag="ALL"
          header="コンテンツを探す"
          postList={postList}
          tags={tags}
          tagsMeta={tagsMeta}
          catsMeta={catsMeta}
        />
        <Nav className="mt-12" />
      </main>
    </Layout>
  );
}

export default IndexTemplate



export const query = graphql`
  query($limit: Int) {
    posts: allMdx(
      limit: $limit,
      sort: {fields: frontmatter___datePublished, order: DESC},
      filter: {
        frontmatter: {draft: {eq: false}},
        slug: {regex: "/^(?!(works/.*|personal-works/.*))/"}
      }
    ) {
      edges {
        node {
          slug
          frontmatter {
            tags
            draft
            eyecatch {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  aspectRatio: 2
                  width: 312
                )
              }
            }
            dateModified(formatString: "MMMM Do, YYYY")
            title
          }
        }
      }
    }
    tags: allMdx(filter: {frontmatter: {draft: {eq: false}}}) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    site {
      siteMetadata {
        tags {
          label
          slug
        }
        categories {
          label
          slug
        }
      }
    }
  }
`
