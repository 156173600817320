import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const listCardArticle = ({title, imageSharp, imageAlt, linkTo, date}) => {
  return (
    <article className="transition-all duration-300 flex w-full max-w-sm py-6">
      <Link className="w-full" to={linkTo}>
        <figure className="w-76 h-38 mx-auto rounded-md overflow-hidden shadow-xl border border-gray-100">
          <GatsbyImage
            className="transform hover:scale-110 transition duration-300"
            image={ getImage( imageSharp ) }
            alt={ imageAlt }
            placeholder="dominantColor"
          />
        </figure>
        {date
          ? (
            <div className="pt-5 px-4 text-center">
              <h2 className="text-base font-bold leading-snug">{title}</h2>
              <span className="text-xs italic text-gray-500">Posted on {date}</span>
            </div>
          )
          : (
            <h2 className="pt-5 px-4 text-base text-center font-bold leading-snug">{title}</h2>
          )
        }
      </Link>
    </article>
  )
}

export default listCardArticle
