const PostListLdjson = ({postList, catsMeta ,catSlug}) => {
  let counter = 0
  return postList.map(({node}) => {
    let result = {}
    if ( node.frontmatter.eyecatch ) {
      counter ++
      let parentSlug
      if (catsMeta) {
        catsMeta.forEach(catMeta => {
          if (node.frontmatter.category==='post') parentSlug = 'post'
          if (node.frontmatter.category===catMeta.label) parentSlug = catMeta.slug
        })
      } else {
        parentSlug = catSlug
      }
      result = {
        position: counter,
        url: `/${parentSlug}/${node.frontmatter.slug}/`,
      }
    }
    return result
  }).filter( x => x )
}

export default PostListLdjson
